import type { CSSObject } from "@emotion/css";

export const buttonResetStyles: CSSObject = {
    all: "unset",
    cursor: "pointer",
};

export const anchorResetStyles: CSSObject = {
    all: "unset",
    cursor: "pointer",
};

export const ulResetStyles: CSSObject = {
    all: "unset",
    listStyle: "none",
};
