import { css } from "@emotion/css";
import { color, space } from "@octopusdeploy/design-system-component-tokens";
import React from "react";
import { ulResetStyles } from "../../utils/resetStyles";
import type { NavigationBarItemData } from "./NavigationBarItem";
import { NavigationBarItem } from "./NavigationBarItem";

export interface NavigationBarProps {
    items: NavigationBarItemData[];
}

export function NavigationBar({ items }: NavigationBarProps) {
    return (
        <nav className={navigationBarStyles}>
            <ul className={ulStyles}>
                {items.map((item) => (
                    <li className={liStyles} key={item.label}>
                        <NavigationBarItem item={item} />
                    </li>
                ))}
            </ul>
        </nav>
    );
}

const navigationBarStyles = css({
    display: "flex",
    alignItems: "center",
    background: color.scale.blue[900],
    padding: space[4],
    width: "100%",
    boxSizing: "border-box",
});

const ulStyles = css({
    ...ulResetStyles,
    display: "flex",
});

const liStyles = css({
    "&:not(:first-child)": {
        marginLeft: space[4],
    },
});
