import { css } from "@emotion/css";
import { borderRadius, borderWidth, space, themeColors, typography } from "@octopusdeploy/design-system-component-tokens";
import type { ChangeEvent } from "react";
import React from "react";
import { size } from "../../mockTokens";
import { MagnifyingGlassIcon } from "../Icon/MagnifyingGlassIcon";
import { ClearInputButton } from "./ClearInputButton";

export interface SearchInputProps {
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    accessibleName: string;
}

export function SearchInput({ value, placeholder, onChange, accessibleName }: SearchInputProps) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = React.useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const handleClear = () => {
        onChange("");
        inputRef.current?.focus();
    };

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    return (
        <div className={inputContainerStyles}>
            <span className={searchIconContainerStyles}>
                <MagnifyingGlassIcon showFocusState={isFocused} />
            </span>

            <input ref={inputRef} className={inputStyles} placeholder={placeholder} value={value} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} aria-label={accessibleName} />

            {value && (
                <span className={clearInputButtonContainerStyles}>
                    <ClearInputButton onClear={handleClear} accessibleName={`Clear ${accessibleName}`} />
                </span>
            )}
        </div>
    );
}

const inputHorizontalPadding = space[4];

const inputContainerStyles = css({
    position: "relative",
});

const inputStyles = css({
    ...typography.body.regular,

    height: size.input,
    width: "100%",
    boxSizing: "border-box",

    transitionProperty: "border-color, outline",
    transitionDuration: "0.2s",

    // Padding needs to account for icons on the side
    paddingLeft: `calc(${inputHorizontalPadding} * 2 + 1em)`,
    paddingRight: `calc(${inputHorizontalPadding} * 2 + 1em)`,

    outline: "transparent 2px solid",
    borderWidth: borderWidth[1],
    borderStyle: "solid",
    borderRadius: borderRadius.medium,

    borderColor: themeColors.border.input.base,
    color: themeColors.text.input,

    "&::placeholder": {
        color: themeColors.text.inputPlaceholder,
    },
    ":hover": {
        borderColor: themeColors.border.input.hover,
    },
    ":focus-visible": {
        outlineColor: themeColors.outline.input.focus,
        borderColor: themeColors.outline.input.focus,
    },
});

const searchIconContainerStyles = css({
    display: "flex",
    alignItems: "center",
    pointerEvents: "none",
    position: "absolute",
    transitionProperty: "color",
    transitionDuration: "0.2s",
    top: 0,
    bottom: 0,
    left: inputHorizontalPadding,
});

const clearInputButtonContainerStyles = css({
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: inputHorizontalPadding,
});
