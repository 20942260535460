import React from "react";
import { ButtonIcon } from "./ButtonIcon";
import { getButtonStyles } from "./buttonStyles";

export interface ButtonProps {
    label: string;
    onClick: () => void;
    size: ButtonSize;
    variant: ButtonVariant;
    color: ButtonColor;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
}

export function Button({ label, onClick, size, variant, color, leftIcon, rightIcon }: ButtonProps) {
    const buttonStyles = getButtonStyles(variant, color, size);

    return (
        <button className={buttonStyles} onClick={() => onClick()}>
            {leftIcon && <ButtonIcon position="left" icon={leftIcon} />}
            {label}
            {rightIcon && <ButtonIcon position="right" icon={rightIcon} />}
        </button>
    );
}

export const buttonVariants = ["solid", "ghost"] as const;
export type ButtonVariant = typeof buttonVariants[number];

export const buttonColors = ["primary", "secondary"] as const;
export type ButtonColor = typeof buttonColors[number];

export const buttonSizes = ["large", "medium", "small", "extra-small"] as const;
export type ButtonSize = typeof buttonSizes[number];
