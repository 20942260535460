export const text = {
    "button": {
        "solid": {
            "primary": {
                "base": "var(--textButtonSolidPrimaryBase)",
                "hover": "var(--textButtonSolidPrimaryHover)",
                "focus": "var(--textButtonSolidPrimaryFocus)",
                "active": "var(--textButtonSolidPrimaryActive)",
                "disabled": "var(--textButtonSolidPrimaryDisabled)"
            },
            "secondary": {
                "base": "var(--textButtonSolidSecondaryBase)",
                "hover": "var(--textButtonSolidSecondaryHover)",
                "focus": "var(--textButtonSolidSecondaryFocus)",
                "active": "var(--textButtonSolidSecondaryActive)",
                "disabled": "var(--textButtonSolidSecondaryDisabled)"
            }
        },
        "ghost": {
            "primary": {
                "base": "var(--textButtonGhostPrimaryBase)",
                "hover": "var(--textButtonGhostPrimaryHover)",
                "focus": "var(--textButtonGhostPrimaryFocus)",
                "active": "var(--textButtonGhostPrimaryActive)",
                "disabled": "var(--textButtonGhostPrimaryDisabled)"
            },
            "secondary": {
                "base": "var(--textButtonGhostSecondaryBase)",
                "hover": "var(--textButtonGhostSecondaryHover)",
                "focus": "var(--textButtonGhostSecondaryFocus)",
                "active": "var(--textButtonGhostSecondaryActive)",
                "disabled": "var(--textButtonGhostSecondaryDisabled)"
            }
        }
    },
    "input": "var(--textInput)",
    "inputPlaceholder": "var(--textInputPlaceholder)",
    "navigationBar": {
        "link": {
            "base": "var(--textNavigationBarLinkBase)",
            "hover": "var(--textNavigationBarLinkHover)",
            "focus": "var(--textNavigationBarLinkFocus)",
            "active": "var(--textNavigationBarLinkActive)",
            "selected": "var(--textNavigationBarLinkSelected)"
        }
    }
} as const;

export const background = {
    "button": {
        "solid": {
            "primary": {
                "base": "var(--backgroundButtonSolidPrimaryBase)",
                "hover": "var(--backgroundButtonSolidPrimaryHover)",
                "focus": "var(--backgroundButtonSolidPrimaryFocus)",
                "active": "var(--backgroundButtonSolidPrimaryActive)",
                "disabled": "var(--backgroundButtonSolidPrimaryDisabled)"
            },
            "secondary": {
                "base": "var(--backgroundButtonSolidSecondaryBase)",
                "hover": "var(--backgroundButtonSolidSecondaryHover)",
                "focus": "var(--backgroundButtonSolidSecondaryFocus)",
                "active": "var(--backgroundButtonSolidSecondaryActive)",
                "disabled": "var(--backgroundButtonSolidSecondaryDisabled)"
            }
        },
        "ghost": {
            "primary": {
                "base": "var(--backgroundButtonGhostPrimaryBase)",
                "hover": "var(--backgroundButtonGhostPrimaryHover)",
                "focus": "var(--backgroundButtonGhostPrimaryFocus)",
                "active": "var(--backgroundButtonGhostPrimaryActive)",
                "disabled": "var(--backgroundButtonGhostPrimaryDisabled)"
            },
            "secondary": {
                "base": "var(--backgroundButtonGhostSecondaryBase)",
                "hover": "var(--backgroundButtonGhostSecondaryHover)",
                "focus": "var(--backgroundButtonGhostSecondaryFocus)",
                "active": "var(--backgroundButtonGhostSecondaryActive)",
                "disabled": "var(--backgroundButtonGhostSecondaryDisabled)"
            }
        }
    },
    "navigationBar": {
        "link": {
            "base": "var(--backgroundNavigationBarLinkBase)",
            "hover": "var(--backgroundNavigationBarLinkHover)",
            "focus": "var(--backgroundNavigationBarLinkFocus)",
            "active": "var(--backgroundNavigationBarLinkActive)",
            "selected": "var(--backgroundNavigationBarLinkSelected)"
        }
    }
} as const;

export const outline = {
    "button": {
        "solid": {
            "primary": {
                "focus": "var(--outlineButtonSolidPrimaryFocus)"
            },
            "secondary": {
                "focus": "var(--outlineButtonSolidSecondaryFocus)"
            }
        },
        "ghost": {
            "primary": {
                "focus": "var(--outlineButtonGhostPrimaryFocus)"
            },
            "secondary": {
                "focus": "var(--outlineButtonGhostSecondaryFocus)"
            }
        }
    },
    "navigationBar": {
        "link": {
            "focus": "var(--outlineNavigationBarLinkFocus)"
        }
    },
    "input": {
        "focus": "var(--outlineInputFocus)"
    }
} as const;

export const border = {
    "input": {
        "base": "var(--borderInputBase)",
        "hover": "var(--borderInputHover)"
    }
} as const;