import * as globals from "./globals";
import type { OctopusTheme } from "./OctopusTheme";
export const darkTheme: OctopusTheme = {
    "textButtonSolidPrimaryBase": globals["color"]["scale"]["white"],
    "textButtonSolidPrimaryHover": globals["color"]["scale"]["white"],
    "textButtonSolidPrimaryFocus": globals["color"]["scale"]["white"],
    "textButtonSolidPrimaryActive": globals["color"]["scale"]["white"],
    "textButtonSolidPrimaryDisabled": globals["color"]["scale"]["white"],
    "textButtonSolidSecondaryBase": globals["color"]["scale"]["grey"]["900"],
    "textButtonSolidSecondaryHover": globals["color"]["scale"]["grey"]["900"],
    "textButtonSolidSecondaryFocus": globals["color"]["scale"]["grey"]["900"],
    "textButtonSolidSecondaryActive": globals["color"]["scale"]["grey"]["900"],
    "textButtonSolidSecondaryDisabled": globals["color"]["scale"]["slate"]["400"],
    "textButtonGhostPrimaryBase": globals["color"]["scale"]["blue"]["500"],
    "textButtonGhostPrimaryHover": globals["color"]["scale"]["blue"]["700"],
    "textButtonGhostPrimaryFocus": globals["color"]["scale"]["blue"]["500"],
    "textButtonGhostPrimaryActive": globals["color"]["scale"]["blue"]["700"],
    "textButtonGhostPrimaryDisabled": globals["color"]["scale"]["blue"]["300"],
    "textButtonGhostSecondaryBase": globals["color"]["scale"]["grey"]["900"],
    "textButtonGhostSecondaryHover": globals["color"]["scale"]["grey"]["900"],
    "textButtonGhostSecondaryFocus": globals["color"]["scale"]["grey"]["900"],
    "textButtonGhostSecondaryActive": globals["color"]["scale"]["grey"]["900"],
    "textButtonGhostSecondaryDisabled": globals["color"]["scale"]["slate"]["400"],
    "textInput": globals["color"]["scale"]["grey"]["900"],
    "textInputPlaceholder": globals["color"]["scale"]["grey"]["500"],
    "textNavigationBarLinkBase": globals["color"]["scale"]["slate"]["100"],
    "textNavigationBarLinkHover": globals["color"]["scale"]["slate"]["100"],
    "textNavigationBarLinkFocus": globals["color"]["scale"]["slate"]["100"],
    "textNavigationBarLinkActive": globals["color"]["scale"]["slate"]["100"],
    "textNavigationBarLinkSelected": globals["color"]["scale"]["slate"]["100"],
    "backgroundButtonSolidPrimaryBase": globals["color"]["scale"]["blue"]["500"],
    "backgroundButtonSolidPrimaryHover": globals["color"]["scale"]["blue"]["600"],
    "backgroundButtonSolidPrimaryFocus": globals["color"]["scale"]["blue"]["500"],
    "backgroundButtonSolidPrimaryActive": globals["color"]["scale"]["blue"]["700"],
    "backgroundButtonSolidPrimaryDisabled": globals["color"]["scale"]["blue"]["300"],
    "backgroundButtonSolidSecondaryBase": globals["color"]["scale"]["slate"]["200"],
    "backgroundButtonSolidSecondaryHover": globals["color"]["scale"]["slate"]["300"],
    "backgroundButtonSolidSecondaryFocus": globals["color"]["scale"]["slate"]["200"],
    "backgroundButtonSolidSecondaryActive": globals["color"]["scale"]["slate"]["400"],
    "backgroundButtonSolidSecondaryDisabled": globals["color"]["scale"]["slate"]["200"],
    "backgroundButtonGhostPrimaryBase": globals["color"]["scale"]["alpha"]["0"],
    "backgroundButtonGhostPrimaryHover": globals["color"]["scale"]["blue"]["100"],
    "backgroundButtonGhostPrimaryFocus": globals["color"]["scale"]["alpha"]["0"],
    "backgroundButtonGhostPrimaryActive": globals["color"]["scale"]["blue"]["200"],
    "backgroundButtonGhostPrimaryDisabled": globals["color"]["scale"]["alpha"]["0"],
    "backgroundButtonGhostSecondaryBase": globals["color"]["scale"]["alpha"]["0"],
    "backgroundButtonGhostSecondaryHover": globals["color"]["scale"]["slate"]["100"],
    "backgroundButtonGhostSecondaryFocus": globals["color"]["scale"]["alpha"]["0"],
    "backgroundButtonGhostSecondaryActive": globals["color"]["scale"]["slate"]["200"],
    "backgroundButtonGhostSecondaryDisabled": globals["color"]["scale"]["alpha"]["0"],
    "backgroundNavigationBarLinkBase": globals["color"]["scale"]["alpha"]["0"],
    "backgroundNavigationBarLinkHover": globals["color"]["scale"]["blue"]["700"],
    "backgroundNavigationBarLinkFocus": globals["color"]["scale"]["alpha"]["0"],
    "backgroundNavigationBarLinkActive": globals["color"]["scale"]["blue"]["800"],
    "backgroundNavigationBarLinkSelected": globals["color"]["scale"]["blue"]["800"],
    "outlineButtonSolidPrimaryFocus": globals["color"]["scale"]["blue"]["400"],
    "outlineButtonSolidSecondaryFocus": globals["color"]["scale"]["blue"]["400"],
    "outlineButtonGhostPrimaryFocus": globals["color"]["scale"]["blue"]["400"],
    "outlineButtonGhostSecondaryFocus": globals["color"]["scale"]["blue"]["400"],
    "outlineInputFocus": globals["color"]["scale"]["blue"]["400"],
    "outlineNavigationBarLinkFocus": globals["color"]["scale"]["blue"]["400"],
    "borderInputBase": globals["color"]["scale"]["slate"]["300"],
    "borderInputHover": globals["color"]["scale"]["slate"]["500"]
};