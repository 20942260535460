import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import type { LogEvent, SerilogCompactFormatLogEvent, StructuredLogEvent, LogLevel, SerilogLogLevel } from "../types";
import { isStructuredLogEvent, isLogEventWithPossibleError } from "../types";

export function convertToSerilogCompactFormat(logEvent: LogEvent): SerilogCompactFormatLogEvent {
    if (isStructuredLogEvent(logEvent)) {
        const possibleStack = getStackForStructuredLogEventWithPossibleError(logEvent);
        const possibleErrorProperties = possibleStack ? { "@x": possibleStack } : {};
        return {
            ...logEvent.propertyValues,
            "@mt": logEvent.messageTemplate,
            "@l": getSerilogLogLevel(logEvent.logLevel),
            SourceContext: logEvent.sourceContext,
            "@t": logEvent.timestamp.toISOString(),
            ...possibleErrorProperties,
        };
    }
    const propertyValues = {
        ...logEvent.context,
        ConsoleParams: logEvent.messageArguments,
    };

    return {
        ...propertyValues,
        "@mt": String(logEvent.message),
        "@t": logEvent.timestamp.toISOString(),
        "@l": getSerilogLogLevel(logEvent.logLevel),
        SourceContext: logEvent.sourceContext,
    };
}

function getStackForStructuredLogEventWithPossibleError(logEvent: StructuredLogEvent) {
    if (isLogEventWithPossibleError(logEvent)) {
        const errorStack = logEvent.error?.stack;
        const capturedStack = logEvent.stack;

        return errorStack ? combineErrorStackAndCapturedStack(errorStack, capturedStack) : capturedStack;
    }

    return null;
}

function combineErrorStackAndCapturedStack(errorStack: string, capturedStack: string | undefined) {
    return capturedStack ? `${errorStack}\nCaptured By\n ${capturedStack}` : errorStack;
}

function getSerilogLogLevel(logLevel: LogLevel): SerilogLogLevel {
    switch (logLevel) {
        case "verbose":
            return "Verbose";
        case "debug":
            return "Debug";
        case "information":
            return "Information";
        case "warning":
            return "Warning";
        case "error":
            return "Error";
        case "fatal":
            return "Fatal";
        default:
            return exhaustiveCheck(logLevel, "Unexpected log level");
    }
}
