import { css, cx } from "@emotion/css";
import { borderRadius, space, themeColors, typography } from "@octopusdeploy/design-system-component-tokens";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { font, size } from "../../mockTokens";
import { buttonResetStyles } from "../../utils/resetStyles";
import type { ButtonSize, ButtonColor, ButtonVariant } from "./Button";

export function getButtonStyles(variant: ButtonVariant, color: ButtonColor, size: ButtonSize) {
    const buttonVariantStyles = getButtonVariantStyles(variant, color);
    const buttonSizeStyles = getButtonSizeStyles(size);

    return cx([baseButtonStyles, buttonSizeStyles, buttonVariantStyles]);
}

const baseButtonStyles = css({
    ...buttonResetStyles,
    ...typography.body.regular,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",

    borderRadius: borderRadius.medium,

    paddingInlineStart: space[4],
    paddingInlineEnd: space[4],
});

function getButtonVariantStyles(variant: ButtonVariant, color: ButtonColor): string {
    const variantLookup = getVariantLookupValue(variant);
    const colorLookup = getColorLookupValue(color);

    return css({
        color: themeColors.text.button[variantLookup][colorLookup].base,
        background: themeColors.background.button[variantLookup][colorLookup].base,
        outline: "transparent solid 3px", // outline must be defined to be able to transition

        ":hover": {
            color: themeColors.text.button[variantLookup][colorLookup].hover,
            background: themeColors.background.button[variantLookup][colorLookup].hover,
        },
        ":focus-visible": {
            color: themeColors.text.button[variantLookup][colorLookup].focus,
            background: themeColors.background.button[variantLookup][colorLookup].focus,
            outline: `${themeColors.outline.button[variantLookup][colorLookup].focus} solid 3px `, // TODO: Should the outline style and width be tokenised?
        },
        ":active": {
            color: themeColors.text.button[variantLookup][colorLookup].active,
            background: themeColors.background.button[variantLookup][colorLookup].active,
        },
        ":disabled": {
            color: themeColors.text.button[variantLookup][colorLookup].disabled,
            background: themeColors.background.button[variantLookup][colorLookup].disabled,
        },
    });
}

function getButtonSizeStyles(buttonSize: ButtonSize): string {
    const sizeLookup = getSizeLookupValue(buttonSize);
    return css({
        height: size.button[sizeLookup],
        fontSize: font.size.button[sizeLookup],
        lineHeight: 1.4,
    });
}

function getVariantLookupValue(buttonVariant: ButtonVariant): keyof typeof themeColors.background.button {
    switch (buttonVariant) {
        case "solid":
            return "solid";
        case "ghost":
            return "ghost";
        default:
            exhaustiveCheck(buttonVariant, `Could not find lookup value for unknown variant: ${buttonVariant}`);
    }
}

function getColorLookupValue(buttonColor: ButtonColor): keyof typeof themeColors.background.button[ButtonVariant] {
    switch (buttonColor) {
        case "primary":
            return "primary";
        case "secondary":
            return "secondary";
        default:
            exhaustiveCheck(buttonColor, `Could not find lookup value for unknown color: ${buttonColor}`);
    }
}

function getSizeLookupValue(buttonSize: ButtonSize): keyof typeof size.button {
    switch (buttonSize) {
        case "large":
            return "large";
        case "medium":
            return "medium";
        case "small":
            return "small";
        case "extra-small":
            return "extra-small";
        default:
            exhaustiveCheck(buttonSize, `Could not find lookup value for unknown size: ${buttonSize}`);
    }
}
