export type LogEvent = StructuredLogEvent | UnstructuredLogEvent;
export type StructuredLogEvent = StructuredLogEventWithNoError | StructuredLogEventWithPossibleError;

interface StructuredLogEventWithNoError extends CommonStructuredLogEventProperties {
    logLevel: LogLevelsWithNoError;
}

interface StructuredLogEventWithPossibleError extends CommonStructuredLogEventProperties {
    logLevel: LogLevelsWithPossibleError;
    error?: Error;
    stack: string | undefined;
}

interface CommonStructuredLogEventProperties {
    timestamp: Date;
    messageTemplate: string;
    propertyValues: PropertyValues;
    sourceContext: string;
}

export interface UnstructuredLogEvent {
    message: unknown;
    messageArguments: PropertyValue[];
    logLevel: LogLevel;
    timestamp: Date;
    context: PropertyValues;
    sourceContext: string;
}

export type LogLevelsWithPossibleError = "warning" | "error" | "fatal";
export type LogLevelsWithNoError = "verbose" | "debug" | "information";

export type LogLevel = LogLevelsWithNoError | LogLevelsWithPossibleError;

export function isStructuredLogEvent(logEvent: LogEvent): logEvent is StructuredLogEvent {
    return "messageTemplate" in logEvent;
}

export function isLogEventWithPossibleError(logEvent: StructuredLogEvent): logEvent is StructuredLogEventWithPossibleError {
    return logEvent.logLevel === "warning" || logEvent.logLevel === "error" || logEvent.logLevel === "fatal";
}

// There are some types we don't support here - such as symbols or functions.
// There is probably never a useful time to pass them to a logger,
// and knowing this fact makes the logging pipeline easier to implement
export type PropertyValue = string | number | object | boolean | undefined | null;

export interface PropertyValues {
    [propertyName: string]: PropertyValue;
}

export type WellKnownPropertyValues<T extends { [name: string]: PropertyValue }> = {
    [key in keyof T]: T[key];
};
