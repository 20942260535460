import { css } from "@emotion/css";
import { space, color, themeColors, typography, borderRadius } from "@octopusdeploy/design-system-component-tokens";
import { anchorResetStyles } from "../../utils/resetStyles";

export const navigationBarItemStyles = css({
    ...anchorResetStyles,
    ...typography.body.bold,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, outline, color",
    transitionDuration: "0.2s",

    height: "2.5rem",

    borderRadius: borderRadius.medium,

    paddingInlineStart: space[4],
    paddingInlineEnd: space[4],

    color: themeColors.text.navigationBar.link.base,
    background: themeColors.background.navigationBar.link.base,
    outline: "transparent solid 3px",

    "&[aria-current=page]": {
        color: themeColors.text.navigationBar.link.selected,
        background: themeColors.background.navigationBar.link.selected,
    },
    ":focus-visible": {
        color: themeColors.text.navigationBar.link.focus,
        background: themeColors.background.navigationBar.link.focus,
        outline: `${color.scale.blue[400]} solid 3px`,
        zIndex: 1,
    },
    ":hover": {
        color: themeColors.text.navigationBar.link.hover,
        background: themeColors.background.navigationBar.link.hover,
    },
    ":active": {
        color: themeColors.text.navigationBar.link.active,
        background: themeColors.background.navigationBar.link.active,
    },
});
